import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { CONFIG } from 'src/config-global';
import { DashboardLayout } from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

// Overview
const IndexPage = lazy(() => import('src/pages/landing-page'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
const OverviewCoursePage = lazy(() => import('src/pages/dashboard/course'));
const AccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// Product
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// User
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// Blog
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// Job
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// Tour
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// File manager
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// App
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// Test render page by role
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// Blank page
const ParamsPage = lazy(() => import('src/pages/dashboard/params'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// About
const AboutPage = lazy(() => import('src/pages/about-us'));
// Psychics
const PhysicDetail = lazy(() => import('src/pages/psychic/details'));
// Category
const LoveReadings = lazy(() => import('src/pages/category'));
// Privacy policy
const PrivacyPolicy = lazy(() => import('src/pages/privacy-policy'));
// Terms of use
const TermsOfUse = lazy(() => import('src/pages/terms-of-use'));
// cookie-policy
const CookiePolicy = lazy(() => import('src/pages/cookie-policy'));
// advisor-terms-and-conditions
const AdvisorTermsAndConditions = lazy(() => import('src/pages/advisor-terms-and-conditions'));
// 404
const NotFoundPage = lazy(() => import('src/pages/error/404'));
// payment-page
const PaymentPage = lazy(() => import('src/pages/payment'));
// superadmin
const Consultants = lazy(() => import('src/pages/consultants'));

const ConsultationsPage = lazy(() => import('src/pages/consultations'));
const MembersPage = lazy(() => import('src/pages/members'));
const CategoriesPage = lazy(() => import('src/pages/categories'));

// add consultant page
const AddConsultantPage = lazy(() => import('src/pages/dashboard/consultant/edit'));
// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </DashboardLayout>
);

export const homeRoutes = () => {
  // eslint-disable-next-line
  const { user } = useAuthContext();

  return [
    {
      path: '/',
      element: layoutContent,
      children: [
        {
          element: user ? (
            user.role?.id === 3 ? (
              <IndexPage />
            ) : user.role?.id === 4 ? (
              <ChatPage />
            ) : (
              <Consultants />
            )
          ) : (
            <IndexPage />
          ),
          index: true,
        },
        {
          path: 'add_consultant',
          element: user ? (
            user?.role?.id === 1 ? (
              <AddConsultantPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <AboutPage />
          ),
        },
        {
          path: 'about-us',
          element: user ? (
            user?.role?.id === 3 ? (
              <AboutPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <AboutPage />
          ),
        },
        {
          path: 'psychic',
          children: [
            {
              path: ':id',
              element: user ? (
                user?.role?.id === 3 ? (
                  <PhysicDetail />
                ) : (
                  <Navigate to="/404" replace />
                )
              ) : (
                <PhysicDetail />
              ),
            },
          ],
        },
        {
          path: 'category',
          children: [
            {
              path: ':slug',
              element: user ? (
                user?.role?.id === 3 ? (
                  <LoveReadings />
                ) : (
                  <Navigate to="/404" replace />
                )
              ) : (
                <LoveReadings />
              ),
            },
          ],
        },
        {
          path: 'blogs',
          element: user ? (
            user?.role?.id === 3 ? (
              <BlogPostsPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <BlogPostsPage />
          ),
        },
        {
          path: 'blog/detail',
          element: user ? (
            user?.role?.id === 3 ? (
              <BlogPostPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <BlogPostPage />
          ),
        },
        {
          path: 'specialities',
          element: user ? (
            user?.role?.id === 3 ? (
              <TourListPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <TourListPage />
          ),
        },
        {
          path: 'privacy-policy',
          element: user ? (
            user?.role?.id === 3 ? (
              <PrivacyPolicy />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <PrivacyPolicy />
          ),
        },
        {
          path: 'terms-of-use',
          element: user ? (
            user?.role?.id === 3 ? (
              <TermsOfUse />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <TermsOfUse />
          ),
        },
        {
          path: 'cookie-policy',
          element: user ? (
            user?.role?.id === 3 ? (
              <CookiePolicy />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <CookiePolicy />
          ),
        },
        {
          path: 'advisor-terms-and-conditions',
          element: user ? (
            user?.role?.id === 3 ? (
              <AdvisorTermsAndConditions />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <AdvisorTermsAndConditions />
          ),
        },
        {
          path: 'chat',
          element: (
            <AuthGuard>
              <ChatPage />
            </AuthGuard>
          ),
        },
        {
          path: 'payment',
          element: user ? (
            user?.role?.id === 3 ? (
              <AuthGuard>
                <PaymentPage />
              </AuthGuard>
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <Navigate to="/404" replace />
          ),
        },
        {
          path: 'account',
          element: (
            <AuthGuard>
              <AccountPage />
            </AuthGuard>
          ),
        },
        { path: 'ecommerce', element: <OverviewEcommercePage /> },
        { path: 'analytics', element: <OverviewAnalyticsPage /> },
        { path: 'banking', element: <OverviewBankingPage /> },
        { path: 'booking', element: <OverviewBookingPage /> },
        { path: 'file', element: <OverviewFilePage /> },
        { path: 'course', element: <OverviewCoursePage /> },
        {
          path: 'user',
          children: [
            { element: <UserProfilePage />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':id/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'product',
          children: [
            { element: <ProductListPage />, index: true },
            { path: 'list', element: <ProductListPage /> },
            { path: ':id', element: <ProductDetailsPage /> },
            { path: 'new', element: <ProductCreatePage /> },
            { path: ':id/edit', element: <ProductEditPage /> },
          ],
        },
        {
          path: 'order',
          children: [
            { element: <OrderListPage />, index: true },
            { path: 'list', element: <OrderListPage /> },
            { path: ':id', element: <OrderDetailsPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <InvoiceListPage />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'post',
          children: [
            { element: <BlogPostsPage />, index: true },
            { path: 'list', element: <BlogPostsPage /> },
            { path: ':title', element: <BlogPostPage /> },
            { path: ':title/edit', element: <BlogEditPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        {
          path: 'dashboard',
          element: CONFIG.auth.skip ? <>{layoutContent}</> : <AuthGuard>{layoutContent}</AuthGuard>,
          children: [
            // { path: 'home', element: <HomePage /> },
            { path: 'chat', element: <ChatPage /> },
          ],
        },
        {
          path: 'job',
          children: [
            { element: <JobListPage />, index: true },
            { path: 'list', element: <JobListPage /> },
            { path: ':id', element: <JobDetailsPage /> },
            { path: 'new', element: <JobCreatePage /> },
            { path: ':id/edit', element: <JobEditPage /> },
          ],
        },
        {
          path: 'tour',
          children: [
            { element: <TourListPage />, index: true },
            { path: 'list', element: <TourListPage /> },
            { path: ':id', element: <TourDetailsPage /> },
            { path: 'new', element: <TourCreatePage /> },
            { path: ':id/edit', element: <TourEditPage /> },
          ],
        },
        { path: 'file-manager', element: <FileManagerPage /> },

        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission', element: <PermissionDeniedPage /> },
        { path: 'params', element: <ParamsPage /> },
        { path: 'blank', element: <BlankPage /> },
        { path: '404', element: <NotFoundPage /> },
        {
          path: 'consultants',
          element: user?.role?.id === 1 ? <Consultants /> : <Navigate to="/404" replace />,
        },
        {
          path: 'consultations',
          element: user ? (
            user.role?.id === 1 || user?.role?.id === 4 ? (
              <ConsultationsPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <LoadingScreen />
          ),
        },
        {
          path: 'members',
          element: user ? (
            user?.role?.id === 1 ? (
              <MembersPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <LoadingScreen />
          ),
        },
        {
          path: 'categories',
          element: user ? (
            user?.role?.id === 1 ? (
              <CategoriesPage />
            ) : (
              <Navigate to="/404" replace />
            )
          ) : (
            <LoadingScreen />
          ),
        },

        {
          path: 'mail',
          element: (
            <AuthGuard>
              {(user?.role?.id === 3 || user?.role?.id === 4) && <MailPage />}
              {user && (user?.role?.id === 1 || user?.role?.id === 2) && (
                <Navigate to="/404" replace />
              )}
            </AuthGuard>
          ),
        },
      ],
    },
  ];
};
